import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Paper } from 'components/Paper';
import { browserLocalstorage } from 'lib/utils';

const Container = styled(Paper)<{ hidden: boolean }>(
  ({ theme, hidden }) => css`
    background: ${theme.color.white};
    bottom: ${theme.spacing.big};
    display: ${hidden ? 'none' : 'flex'};
    flex-direction: column;
    left: ${theme.spacing.big};
    padding: ${theme.spacing.big};
    position: fixed;
    right: ${theme.spacing.big};
    z-index: 10;

    h3 {
      ${theme.font(18, 'BOLD')};
      color: ${theme.color.blue[300]};
      margin-bottom: ${theme.spacing.mid};
    }

    button {
      ${theme.font(16)};
      border: 0;
      background: none;
      align-self: flex-end;
      color: ${theme.color.blue[400]};
      margin-top: ${theme.spacing.big};

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    ${theme.onScreen('SMALL_DESKTOP')} {
      left: unset;
      width: 50%;
    }
  `
);

export const CookieAlert: React.FC = () => {
  const [isAccepted, setIsAccepted] = useState<boolean>(browserLocalstorage().getItem('cookie_accept') != null);
  const handleAccept = (): void => {
    browserLocalstorage().setItem('cookie_accept', '1');
    setIsAccepted(true);
  };

  return (
    <Container depth={4} hidden={isAccepted}>
      <h3>Pliki cookies</h3>
      <p>
        Ta strona wykorzystuje pliki cookies w celu zbierania anonimowych statystyk. Dalsze korzystanie z witryny jest
        jednoznaczne z wyrażeniem zgody na ich wykorzystanie.
      </p>
      <button type="button" onClick={handleAccept}>
        Rozumiem i akceptuję
      </button>
    </Container>
  );
};
