import { navigate, useStaticQuery, graphql } from 'gatsby';
import { parse, stringify } from 'query-string';
import { useContext, useCallback } from 'react';
import { DefaultTheme, ThemeContext } from 'styled-components';

import { Offer, Product, SharpImage, Method } from 'database';

import { browserWindow } from './utils';

export function useTheme(): DefaultTheme {
  const theme = useContext(ThemeContext);
  if (theme === undefined) {
    throw Error('Cannot use ThemeContext outside ThemeProvider');
  }

  return theme;
}

export function useConfig(): Config {
  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
    const config: Config = require('config.json');
    if (Object.keys(config).length === 0) {
      throw Error('Cannot load an empty config');
    }

    return config;
  } catch (e) {
    throw Error(e);
  }
}
type UseData = () => {
  products: Product.Data[];
  offer: Offer.Data[];
  methods: Method.Data[];
};

export const useData: UseData = () => {
  const queryResponse = useStaticQuery(graphql`
    query OfferQuery {
      allOfferJson {
        nodes {
          id
          slug
          title
          shortDescription
          description
          isMain
          type
        }
      }
      allProductsJson {
        nodes {
          id
          categories
          images {
            src {
              childImageSharp {
                fluid(maxWidth: 700, quality: 100) {
                  src
                }
                fixed(width: 1537, height: 1058) {
                  src
                }
              }
            }
          }
          description
          shortDescription
          factors
          isOnMainPage
          name
          plate
          slug
          technologies
        }
      }
      allMethodsJson {
        nodes {
          title
          img {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                src
              }
              fixed(width: 400, height: 300) {
                src
              }
            }
          }
          description
          spec
        }
      }
    }
  `);

  return {
    products: queryResponse.allProductsJson.nodes,
    offer: queryResponse.allOfferJson.nodes,
    methods: queryResponse.allMethodsJson.nodes
  };
};

export interface ProcessedImage {
  fixed: string;
  fluid: string;
}

export function getSharpImage(image: SharpImage): ProcessedImage {
  return {
    fixed: image.src.childImageSharp?.fixed?.src,
    fluid: image.src.childImageSharp.fluid.src
  };
}

export const useProductImage = (slug: string): ProcessedImage => {
  const { products } = useData();

  return products.filter((product) => product.slug === slug).map((product) => getSharpImage(product.images[0]))[0];
};

type UseSearchQueryReaderReturnType<T> = [T, (values: T) => void];
export const useSearchQueryReader = <T extends Record<string, unknown>>(
  defaultFilters: Partial<T> = {}
): UseSearchQueryReaderReturnType<T> => {
  const { search } = browserWindow().location;

  const onChange = useCallback<UseSearchQueryReaderReturnType<T>[1]>(
    (values) => {
      const searchFilters = { ...parse(search), ...values };
      navigate(`?${stringify(searchFilters)}`);
    },
    [search]
  );

  return [{ ...defaultFilters, ...parse(search) } as T, onChange];
};
