import React from 'react';
import { Helmet } from 'react-helmet';

import favicon from 'images/favicon.svg';
import { useConfig } from 'lib/hooks';

function ensureSlash(href: string): string {
  if (!href.endsWith('/')) {
    return `${href}/`;
  }

  return href;
}

export const SEO: React.FC<{ title: string; canonicalHref?: string }> = ({ title, canonicalHref = '/' }) => {
  const { meta } = useConfig();
  const URL = 'https://bachem.pl';

  return (
    <Helmet
      title={`${title} - Bachem`}
      meta={[
        { name: 'charSet', content: 'utf-8' },
        { name: 'description', content: meta.description },
        { name: 'keywords', content: meta.keywords }
      ]}
    >
      <html lang="pl" />
      <link rel="canonical" href={ensureSlash(`${URL}${canonicalHref}`)} />
      <link rel="icon" type="image/svg" href={favicon} sizes="16x16" />
      <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
};
