import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import { CookieAlert } from 'components/CookieAlert';

import { Footer } from '../components/Footer';
import { Navigation } from '../components/Navigation';

const GlobalStyle = createGlobalStyle`
  ${reset};

  * {
    font-family: inherit;
    box-sizing: border-box;
  }
  
  body, html {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 1.4;
  }

  
  a { 
    color: inherit;
    text-decoration: none;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  overflow-x: hidden;
`;

const Layout: React.FC = ({ children }) => (
  <>
    <GlobalStyle />
    <Navigation />
    <Container>
      <CookieAlert />
      <div style={{ display: 'flex', flexDirection: 'column' }}>{children}</div>
      <Footer />
    </Container>
  </>
);

export default Layout;
