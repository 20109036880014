import React from 'react';
import styled, { css } from 'styled-components';

import { FastOfferNav } from 'components/FastOfferNav';
import { Icon } from 'components/Icon';

import { Location } from './components/Location';
import { Logo } from './components/Logo';
import { Menu, StyledMenu } from './components/Menu';

const MenuOpenToggler = styled.input(
  ({ theme }) => css`
    cursor: pointer;
    display: block;
    opacity: 0;
    position: absolute;
    right: ${theme.spacing.big};

    + ${StyledMenu} {
      display: none;
    }

    &:checked {
      + ${StyledMenu} {
        display: flex;
      }
    }

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      + ${StyledMenu} {
        display: flex;
      }

      &:checked {
        + ${StyledMenu} {
          display: flex;
        }

        display: none;
      }
    }
  `
);

const MenuOpenIcon = styled(Icon)(
  ({ theme }) => css`
    fill: ${theme.color.white};
    position: absolute;

    right: ${theme.spacing.big};

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      display: none;
    }
  `
);

const LogoNav = styled(Logo)`
  margin-left: ${({ theme }) => theme.spacing.huge};
`;

const Container = styled.nav(
  ({ theme }) => css`
    align-items: center;
    background: ${theme.color.blue[100]};
    display: grid;
    grid-template-columns: auto auto;
    height: ${theme.size.menuHeight};
    justify-content: space-between;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 3;

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      grid-template-columns: 1fr 50vw 1fr;
    }

    ${theme.onScreen('MEDIUM_DESKTOP')} {
      grid-template-columns: 1fr 960px 1fr;
    }

    ${theme.onScreen('DESKTOP')} {
      grid-template-columns: 1fr 1350px 1fr;
    }
  `
);

export const Navigation: React.FC = () => (
  <>
    <Container>
      <LogoNav showSubtext={false} />
      <MenuOpenIcon name="menu" />
      <MenuOpenToggler type="checkbox" />
      <Menu />
      <Location />
    </Container>
    <FastOfferNav exclude={['/', '/oferta/']} />
  </>
);
