import { Product } from 'database';

export const FactorDetail = {
  [Product.Factor.SCRATCH_RESISTENT]: {
    description: 'Ochrona przed zarysowaniami'
  },
  [Product.Factor.MULTI_COLORS]: {
    description: 'Użyto wiele kolorów'
  },
  [Product.Factor.ACID_RESISTENT]: {
    description: 'Odporność na warunki szkodliwe'
  },
  [Product.Factor.STAINLESS]: {
    description: 'Zwiększona odporność na rdzę'
  },
  [Product.Factor.PRINT_ABRASSION_RESISTENT]: {
    description: 'Trwałe napisy'
  },
  [Product.Factor.COMPLICATED_PATTERNS]: {
    description: 'Zastosowane skomplikowane wzory'
  },
  [Product.Factor.FLEXIBLE_SHAPE]: {
    description: 'Łatwa manipulacja kształtem'
  },
  [Product.Factor.DOUBLE_SIDE_PRINT]: {
    description: 'Druk dwustronny'
  },
  [Product.Factor.PRECISE_CUT]: {
    description: 'Precyzyjne wycięcie'
  },
  [Product.Factor.SOFT_MATERIAL]: {
    description: 'Miękki materiał'
  }
};

export function isTechnologyMatch(target: Product.Technology[], matchWith: Product.Technology[]): boolean {
  return target.some((tech) => matchWith.includes(tech));
}

export function browserWindow(): typeof window {
  return typeof window !== 'undefined'
    ? window
    : (({
        search: '',
        location: {
          pathname: '',
          origin: ''
        },
        scrollTo: (..._args: unknown[]) => null,
        addEventListener: (..._args: unknown[]) => null,
        removeEventListener: (..._args: unknown[]) => null
      } as unknown) as typeof window);
}

export function browserLocalstorage(): typeof localStorage {
  return typeof localStorage !== 'undefined'
    ? localStorage
    : (({
        getItem: (..._args: unknown[]) => '',
        setItem: (..._args: unknown[]) => null
      } as unknown) as typeof localStorage);
}
