export namespace Method {
  export interface Data {
    title: string;
    description: string[];
    img: {
      childImageSharp: {
        fluid: {
          src: string;
        };
        fixed: {
          src: string;
        };
      };
    };
    spec: string[];
  }
}
