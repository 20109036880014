import React from 'react';

import { InfoSummaryElements } from './elements';

const { Container, Field, Label } = InfoSummaryElements;

export const InfoSummary: React.FC = () => (
  <Container>
    <Field>
      <Label>Biuro i Hala Produkcyjna</Label>
      <span>ul. Kiełczowska 68D</span>
      <span>51-315 Wrocław</span>
      <span>Polska</span>
    </Field>
    <Field>
      <Label>Telefon</Label>
      <a href="tel:+48713456544">+48 71 345 65 44</a>
    </Field>
    <Field>
      <Label>Firma</Label>
      <span>Bachem Tabliczki znamionowe</span>
      <span>NIP: 8951111080</span>
      <span>REGON: 932079069</span>
    </Field>
    <Field>
      <Label>Spółka</Label>
      <span>Bachem Sp. z o.o.</span>
      <span>NIP: 8952239563</span>
      <span>REGON: 521519954</span>
    </Field>
  </Container>
);
