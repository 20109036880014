import { Link } from 'gatsby';
import React from 'react';
import styled, { css } from 'styled-components';

export const StyledMenu = styled.ul(
  ({ theme }) => css`
    align-items: center;
    background: ${theme.color.blue[100]};
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: calc(100vh - ${theme.size.menuHeight});
    left: 0;
    list-style: none;
    position: fixed;
    right: 0;
    top: ${theme.size.menuHeight};

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      background: none;
      flex-direction: row;
      height: 100%;
      justify-content: unset;
      position: static;
    }
  `
);

const MenuItem = styled.li(
  ({ theme }) => css`
    flex: 1 1 auto;
    width: 100%;

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      height: 100%;
    }

    ${theme.onScreen('SMALL_DESKTOP')} {
      flex: none;
      width: auto;

      &:first-child {
        margin-left: 0;
      }
    }
  `
);

const MenuLink = styled(Link)(
  ({ theme }) => css`
    ${theme.font(16, 'BOLD')};

    align-items: center;
    color: ${theme.color.white};
    display: flex;
    height: 100%;

    justify-content: center;
    width: 100%;

    ${theme.onScreen('SMALL_DESKTOP')} {
      padding: 0 ${theme.spacing.huge};
    }

    &.activeLink,
    &:hover {
      background: ${theme.color.blue[400]};
    }
  `
);

interface MenuItemProps {
  label: string;
  to: string;
  hasSubpages: boolean;
}

const MENU_ITEMS: MenuItemProps[] = [
  { label: 'Strona główna', to: '/', hasSubpages: false },
  { label: 'O firmie', to: '/ofirmie/', hasSubpages: true },
  { label: 'Oferta', to: '/oferta/', hasSubpages: true },
  { label: 'Kontakt', to: '/kontakt/', hasSubpages: false }
];

export const Menu: React.FC<{ className?: string }> = ({ className }) => (
  <StyledMenu className={className}>
    {MENU_ITEMS.map((item) => (
      <MenuItem key={item.label}>
        <MenuLink to={item.to} activeClassName="activeLink" partiallyActive={item.hasSubpages}>
          {item.label}
        </MenuLink>
      </MenuItem>
    ))}
  </StyledMenu>
);
