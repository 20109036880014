import React from 'react';

import { LocationElements } from './elements';

const { Container, City, Phone } = LocationElements;

export const Location: React.FC = () => {
  return (
    <Container>
      <City>Wrocław</City>
      <Phone href="tel:+48713456544">+48 71 345 65 44</Phone>
    </Container>
  );
};
