import React from 'react';

import { InfoSummary } from './components/InfoSummary';
import { FooterElements } from './elements';

const { Container, CopyrightEssence, ZOOInfo, RzetelnaBanner } = FooterElements;

export const Footer: React.FC = () => (
  <Container>
    <InfoSummary />
    <ZOOInfo>
      Spółka wpisana do Krajowego Rejestru Sądowego przez Sąd Rejonowy dla Wrocławia- Fabrycznej we Wrocławiu VI Wydział
      Gospodarczy pod nr 0000961515. Kapitał zakładowy 100 000,00 zł opłacony w całości.
    </ZOOInfo>
    <RzetelnaBanner>
      <a target="_blank" href="https://wizytowka.rzetelnafirma.pl/8680KORX" rel="noreferrer follow">
        <img
          src="https://aktywnybaner.rzetelnafirma.pl/ActiveBanner/GetActiveBannerImage/13/8680KORX"
          alt="Rzetelna Firma Bachem"
        />
      </a>
    </RzetelnaBanner>
    <CopyrightEssence>Copyright &copy; bachem.pl 2023. All rights reserved.</CopyrightEssence>
  </Container>
);
