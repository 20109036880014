import * as eva from 'eva-icons';
import React, { useEffect } from 'react';

export const Icon: React.FC<{ title?: string; name: string; className?: string }> = ({
  title,
  name,
  className = ''
}) => {
  useEffect(() => {
    eva.replace();
  }, [name]);

  return <i data-eva={name} className={className} title={title} />;
};
