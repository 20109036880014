/* eslint-disable no-shadow */

export interface SharpImage {
  src: {
    childImageSharp: {
      fluid: {
        src: string;
      };
      fixed: {
        src: string;
      };
    };
  };
}

export namespace Product {
  export enum Factor {
    SCRATCH_RESISTENT = 'SCRATCH_RESISTENT',
    MULTI_COLORS = 'MULTI_COLORS',
    ACID_RESISTENT = 'ACID_RESISTENT',
    STAINLESS = 'STAINLESS',
    PRINT_ABRASSION_RESISTENT = 'PRINT_ABRASSION_RESISTENT',
    COMPLICATED_PATTERNS = 'COMPLICATED_PATTERNS',
    FLEXIBLE_SHAPE = 'FLEXIBLE_SHAPE',
    DOUBLE_SIDE_PRINT = 'DOUBLE_SIDE_PRINT',
    PRECISE_CUT = 'PRECISE_CUT',
    SOFT_MATERIAL = 'SOFT_MATERIAL'
  }

  export enum Plate {
    ALUMINIUM = 'Aluminium anodowane',
    STAINLESS_STEEL = 'Stal nierdzewna',
    ACID_RES_STEEL = 'Stal kwasoodporna',
    LAMINATE = 'Laminat grawerski',
    SELF_STICK_FOIL = 'Folia samoprzylepna',
    PCV = 'Tworzywo PCV',
    BRASS = 'Mosiądz'
  }

  export enum Technology {
    LASER = 'Grawerowanie laserem',
    SCREEN_PRINT = 'Sitodruk',
    GRAVING = 'Wycinane grawerem',
    ETCHING = 'Trawienie'
  }

  export interface Data {
    id: string;
    slug: string;
    categories: string[];
    name: string;
    shortDescription: string;
    description: string;
    isOnMainPage: boolean;
    images: SharpImage[];
    factors: Factor[];
    colors: string[];
    plate: Plate;
    plateThickness: number[];
    cornerRoundness: number[];
    openings: number[];
    technologies: Technology[];
  }
}
