import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

const Container = styled.nav(
  ({ theme }) => css`
    display: none;

    ${theme.onScreen('SMALL_DESKTOP')} {
      background: ${theme.color.gray[300]};
      border-bottom: 1px solid ${theme.color.gray[200]}25;
      display: flex;
      justify-content: center;
      overflow: hidden;
      position: sticky;
      top: calc(${theme.size.menuHeight});
      width: 100%;
      z-index: 3;
    }
  `
);

const OfferLink = styled(Link)<{ isActivePage: boolean }>(
  ({ theme, isActivePage }) => css`
    ${theme.font(13, 'SEMIBOLD')};
    color: ${theme.color.blue[200]};
    padding: 8px ${theme.spacing.big};
    position: relative;

    &:hover {
      color: ${theme.color.blue[400]};
      text-decoration: underline;
    }

    &:after {
      content: '';
      right: 0;
      height: 30%;
      width: 1px;
      background: ${theme.color.gray[200]}30;
      position: absolute;
      top: 35%;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    ${isActivePage
      ? css`
          color: ${theme.color.blue[400]};

          &:hover {
            color: ${theme.color.blue[400]};
            text-decoration: none;
          }
        `
      : ''};
  `
);

export const FastOfferNavElements = {
  Container,
  OfferLink
};
