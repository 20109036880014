import React from 'react';

import { useData } from 'lib/hooks';
import { browserWindow } from 'lib/utils';

import { FastOfferNavElements } from './elements';

const { Container, OfferLink } = FastOfferNavElements;

export const FastOfferNav: React.FC<{ exclude: string[] }> = ({ exclude = [] }) => {
  const { offer } = useData();

  return browserWindow().location.pathname !== '' && !exclude.includes(browserWindow().location.pathname) ? (
    <Container>
      {offer.map((o) => (
        <OfferLink
          key={o.slug}
          to={`/oferta/${o.slug}/`}
          isActivePage={browserWindow().location.pathname.includes(o.slug)}
        >
          {o.title}
        </OfferLink>
      ))}
    </Container>
  ) : null;
};
