import styled, { css } from 'styled-components';

const Container = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: minmax(240px, 1fr);
    justify-content: space-between;
    justify-content: center;
    padding: ${theme.spacing.big};

    ${theme.onScreen('TABLET')} {
      grid-template-columns: repeat(2, 180px);
      justify-content: space-evenly;
    }

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      grid-template-columns: repeat(2, 240px);
      justify-content: space-evenly;
    }

    ${theme.onScreen('SMALL_DESKTOP')} {
      grid-template-columns: repeat(4, 240px);
    }
  `
);

const Field = styled.div(
  ({ theme }) => css`
    align-items: flex-start;
    color: ${theme.color.gray[100]};
    display: flex;
    flex-direction: column;
    font-weight: 600;
    margin-bottom: ${theme.spacing.huge};

    a {
      &:hover {
        text-decoration: underline;
      }
    }
  `
);

const Label = styled.span(
  ({ theme }) => css`
    color: ${theme.color.blue[400]};
    font-weight: 800;
  `
);

export const InfoSummaryElements = {
  Container,
  Field,
  Label
};
