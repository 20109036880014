import styled, { css } from 'styled-components';

interface PaperProps {
  background?: string;
  depth?: number;
  round?: number;
  padding?: number;
}

export const Paper = styled.div<PaperProps>(
  ({ theme, ...props }) => css`
    background: ${props.background ? props.background : theme.color.white};
    border-radius: ${props.round !== undefined ? `${props.round}px` : theme.radius.main};
    padding: ${props.padding !== undefined ? `${props.padding}px` : theme.spacing.big};
  `,
  ({ theme, depth }) => {
    switch (depth) {
      case 0:
        return css`
          box-shadow: ${theme.shadow.depth0};
        `;
      case 1:
        return css`
          box-shadow: ${theme.shadow.depth1};
        `;
      case 2:
        return css`
          box-shadow: ${theme.shadow.depth2};
        `;
      case 3:
        return css`
          box-shadow: ${theme.shadow.depth3};
        `;
      case 4:
        return css`
          box-shadow: ${theme.shadow.depth4};
        `;
      case 5:
        return css`
          box-shadow: ${theme.shadow.depth5};
        `;
      case 6:
        return css`
          box-shadow: ${theme.shadow.depth6};
        `;
      default:
        return css`
          box-shadow: ${theme.shadow.default};
        `;
    }
  }
);
