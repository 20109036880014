import styled, { css } from 'styled-components';

const Container = styled.footer(
  ({ theme }) => css`
    margin: auto;
    margin-bottom: ${theme.spacing.huge};
    margin-top: 20px;
    max-width: 1180px;
    width: 100%;

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      margin-top: 120px;
    }
  `
);

const CopyrightEssence = styled.div(
  ({ theme }) => css`
    color: ${theme.color.gray[200]};
    font-weight: 600;
    padding: ${theme.spacing.big} 0;
    text-align: center;
    width: 100%;
  `
);

const ZOOInfo = styled(CopyrightEssence)`
  margin: 0 auto;
  width: 75%;
`;

const RzetelnaBanner = styled(CopyrightEssence)`
  margin: 0 auto;
`;

export const FooterElements = {
  Container,
  CopyrightEssence,
  ZOOInfo,
  RzetelnaBanner
};
