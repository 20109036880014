import styled, { css } from 'styled-components';

const Container = styled.div(
  ({ theme }) => css`
    display: none;

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      padding: ${theme.spacing.small} ${theme.spacing.huge};
    }
  `
);

const City = styled.div(
  ({ theme }) => css`
    ${theme.font(18, 'EXTRABOLD')};
    color: ${theme.color.blue[400]};
    line-height: 1;
  `
);

const Phone = styled.a(
  ({ theme }) => css`
    ${theme.font(14, 'BOLD')};
    color: ${theme.color.white};
    line-height: 1;

    &:hover {
      text-decoration: underline;
    }
  `
);

export const LocationElements = {
  Container,
  City,
  Phone
};
